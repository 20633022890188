// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-backend-fundamentals-js": () => import("./../../../src/pages/backend-fundamentals.js" /* webpackChunkName: "component---src-pages-backend-fundamentals-js" */),
  "component---src-pages-coding-challenges-js": () => import("./../../../src/pages/coding-challenges.js" /* webpackChunkName: "component---src-pages-coding-challenges-js" */),
  "component---src-pages-course-details-js": () => import("./../../../src/pages/course-details.js" /* webpackChunkName: "component---src-pages-course-details-js" */),
  "component---src-pages-frontend-fundamentals-js": () => import("./../../../src/pages/frontend-fundamentals.js" /* webpackChunkName: "component---src-pages-frontend-fundamentals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-polyglot-js": () => import("./../../../src/pages/polyglot.js" /* webpackChunkName: "component---src-pages-polyglot-js" */),
  "component---src-pages-react-js": () => import("./../../../src/pages/react.js" /* webpackChunkName: "component---src-pages-react-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

